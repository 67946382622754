<!-- TODO: Remember to use Lazy load for images -->
<template>
  <div class="position-relative">
    <!-- tags -->
    <div
      class="bg-white rounded p-2 pt-5 extra_info w-100 d-flex justify-content-between"
      @click.self="goto_class(item.classLink)"
    >
      <div class="d-flex" @click="goto_class(item.classLink)">
        <!-- type of workout -->
        <p class="m-0 p-1 px-2 bg-light rounded mx-1">{{ item.classType }}</p>
        <!-- where is it? -->
        <p class="m-0 p-1 px-2 bg-light rounded mx-1">{{ item.classLinkType[0] }} Live</p>
      </div>
      <div class="">
        <p class="m-0 p-1 px-2 text-info underline pointer" @click="$emit('showMore', item)">More</p>
      </div>
    </div>
    <div
      v-lazy:background-image="get_background_image(item)"
      class="rounded p-2 position-relative class_card shadow"
      @click="goto_class(item.classLink)"
    >
      <div class="background_cover rounded"></div>
      <div class="body position-relative">
        <div class="d-flex justify-content-between mb-5">
          <div style="z-index: 3">
            <!-- location -->
            <h6 class="text-white">
              <i class="fas fa-user-circle mr-1"></i>
              {{ item.instructor }}
            </h6>
            <!-- location -->
            <div v-if="item.country == null" class="mb-5"></div>
            <h6 v-if="item.country != null" class="text-white mb-3">
              <i class="fas fa-map-marker-alt ml-1 mr-2"></i>
              <a v-if="item.city != null">{{ item.city }}, </a><a>{{ item.country }}</a>
            </h6>
          </div>
          <!-- length -->
          <div class="d-inline-block">
            <p class="bg-primary text-white px-2 rounded m-0 mb-3">{{ item.duration / 60 }} mins</p>
          </div>
        </div>
        <div class="mr-5">
          <h5 class="font-weight-bold text-white m-0 mr-1" style="text-transform: capitalize;">
            {{ item.className.toLowerCase() }}
          </h5>
        </div>
        <div class="price rounded p-2">
          <h5 v-if="item.price != 0" class="font-weight-bold m-0 border-0">{{ item.currency }}{{ item.price }}</h5>
          <h5 v-else class="font-weight-bold m-0 border-0">Free</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['item'],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    /** *
     * go to classes
     */
    goto_class(link) {
      window.open(link, '_blank');
    },
    /** *
     * get background images
     */
    get_background_image(item) {
      if (item.classType.toLowerCase() == 'hiit') {
        return 'https://images.unsplash.com/photo-1535743686920-55e4145369b9?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1489&q=80';
      }
      if (
        item.classType.toLowerCase() == 'yoga'
        || item.classType.toLowerCase() == 'flexibility'
        || item.classType.toLowerCase() == 'medidtation'
      ) {
        return 'https://images.unsplash.com/flagged/photo-1573556291531-a5d957d7e4e7?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80';
      }
      if (item.classType.toLowerCase() == 'pilates') {
        return 'https://images.unsplash.com/photo-1518611012118-696072aa579a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80';
      }
      if (item.classType.toLowerCase() == 'strength' || item.classType.toLowerCase() == 'suspension') {
        return 'https://images.unsplash.com/photo-1584735935682-2f2b69dff9d2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1351&q=80';
      }
      if (item.classType.toLowerCase() == 'core') {
        return 'https://images.unsplash.com/photo-1593308610775-48f0102f1722?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1900&q=80';
      }
      if (item.classType.toLowerCase() == 'ballet') {
        return 'https://images.unsplash.com/photo-1555656220-46e30749d330?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80';
      }
      if (item.classType.toLowerCase() == 'cardio') {
        return 'https://images.unsplash.com/flagged/photo-1556746834-cbb4a38ee593?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80';
      }
      if (item.classType.toLowerCase() == 'dance') {
        return 'https://images.unsplash.com/photo-1535525153412-5a42439a210d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80';
      }
      if (item.classType.toLowerCase() == 'barre') {
        return 'https://images.unsplash.com/photo-1518310383802-640c2de311b2?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80';
      }
      if (item.classType.toLowerCase() == 'cycling') {
        return 'https://images.unsplash.com/photo-1511877150299-3622fc88aee3?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80';
      }
      if (item.classType.toLowerCase() == 'boxing') {
        return 'https://images.unsplash.com/photo-1583473848882-f9a5bc7fd2ee?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80';
      }
      if (
        item.classType.toLowerCase() == 'martial arts'
        || item.classType.toLowerCase() == 'tai chi'
        || item.classType.toLowerCase() == 'aerial'
      ) {
        return 'https://images.unsplash.com/photo-1555597673-b21d5c935865?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80';
      }

      return 'https://images.unsplash.com/photo-1558017487-06bf9f82613a?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1305&q=80';
    },
  },
};
</script>

<style scoped>
.class_card {
  /* background-image: url('/static/assets/img/hiit.jpeg'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.profile_image {
  height: 70px;
  weight: 70px;
}

.background_cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 0;
}

.body {
  z-index: 1;
}

.price {
  position: absolute;
  background: #f3f2f7;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.extra_info {
  position: absolute;
  bottom: -53px;
}
</style>
